<template>
    <v-card class="fill-height" fluid>
        <v-toolbar dark color="primary">
            <router-link :to="{ path: '/' }" tag="v-btn">
                <v-btn icon dark>
                    <v-icon>close</v-icon>
                </v-btn>
            </router-link>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>

        <v-card color="primary">
            <v-card-text primary-title class="white--text">{{ subtitle }}</v-card-text>
        </v-card>
        <v-container class="text-center" v-if="step == 0">
            <v-form ref="form" v-model="valid" @submit.prevent="send">
                <v-text-field type="email" v-model="input.email" :rules="[rules.required, rules.email]" :label="$t('E-mail')" required />

                <v-btn color="primary" @click="send" :loading="saving">{{ $t("Recuperar senha") }}</v-btn>
            </v-form>
        </v-container>
        <v-container class="text-center" v-else>
            <v-form ref="formStep2" v-model="valid" v-if="this.input.token" @submit.prevent="changePassword">
                <v-text-field v-model="input.email" label="E-mail" disabled />
                <v-text-field
                    :label="$t('Digite a senha')"
                    v-model="input.password"
                    min="8"
                    :rules="[rules.required, rules.password]"
                    :append-icon="show1 ? 'visibility' : 'visibility_off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    counter
                    required
                />
                <v-text-field
                    :label="$t('Confirme a senha')"
                    v-model="confirmPassword"
                    min="8"
                    :rules="confirmPasswordRules"
                    :append-icon="show2 ? 'visibility' : 'visibility_off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    counter
                    required
                />

                <v-switch v-model="keep" inset :label="$t('Manter logado')" />

                <v-btn color="primary" @click="changePassword" :loading="saving">{{ $t("Trocar senha") }}</v-btn>
            </v-form>
            <v-container v-else>
                <v-row dense>
                    <v-col cols="12">
                        <v-card color="primary" dark>
                            <v-card-title class="headline">{{ $t("Infelizmente o link está vencido.") }}</v-card-title>

                            <v-card-subtitle>
                                {{ $t("Para sua segurança o link de acesso tem validade de 30 minutos. Para recriar a senha clique 'Reenviar' e verifique novamente seu e-mail.") }}
                            </v-card-subtitle>

                            <v-card-actions>
                                <v-btn text @click="sendLink" :loading="saving">{{ $t("Reenviar") }}</v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-progress-linear v-if="saving" indeterminate/>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-card>
</template>

<script>
import jwt from "@/helpers/jwt";
import rules from "@/helpers/rules";

export default {
    name: "Recovery",
    mounted() {
        this.$store.commit("CLEAR_LOGIN");
        if (this.$route.params.token) {
            this.step = 1;
            this.input.email = jwt.getEmail(this.$route.params.token);

            if (jwt.checkToken(this.$route.params.token)) {
                this.input.token = this.$route.params.token;
            }
        }

        this.title = this.step == 0 ? this.$t("Esqueceu sua senha?") : this.$t("Recrie sua senha!");
        this.subtitle =
            this.step == 0
                ? this.$t("Digite o seu e-mail abaixo e já resolveremos isso.")
                : this.$t("Por questões de segurança não sabemos sua senha, será necessário recriar-la.");
    },
    data() {
        return {
            jwt,
            rules,
            valid: true,
            saving: false,
            step: 0,
            title: "",
            confirmPassword: null,
            show1: false,
            show2: false,
            keep: true,
            input: {
                email: null,
                password: null,
                token: null,
            },
            confirmPasswordRules: [(v) => v === this.input.password || this.$t("Senha diferentes")],
        };
    },
    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.sendLink();
            }
        },
        sendLink() {
            this.saving = true;
            this.$http
                .post("request-recovery", { email: this.input.email })
                .then((result) => {
                    this.saving = false;
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Verifique seu email."));
                })
                .catch((error) => {
                    this.saving = false;
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Não foi possível enviar sua solicitação."));
                });
        },
        changePassword() {
            if (this.$refs.formStep2.validate()) {
                this.saving = true;
                this.$http
                    .post("recovery", this.input)
                    .then((result) => {
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Cadastro concluído com sucesso."));
                        this.forceLogin();
                    })
                    .catch((error) => {
                        this.saving = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Não foi possível enviar sua solicitação."));
                    });
            }
        },
        forceLogin() {
            this.saving = true;
            this.$http
                .post("auth", this.input)
                .then((response) => {
                    this.$store.commit("SET_LOGIN", { response, email: this.input.email, remember: this.keep, password: this.keep ? this.input.password : null });
                    this.$emit("login");
                    this.$router.replace({ path: "/" });
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Usuário e/ou Senha incorretos."));
                    this.$router.replace({ path: "/login" });
                });
        },
    },
};
</script>